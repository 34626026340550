<template>
  <div class="container layout-container qclosed" v-loading="regening" element-loading-text="正在重新生成，大约需要3~10分钟">
    <div class="layout-bar">
      <el-button type="primary" @click="reload()">刷新</el-button>
      <el-button type="primary" @click="showCreate()" v-if="editable">手动创建</el-button>
      <el-button type="primary" @click="regen()" v-if="editable">重新生成</el-button>
      <el-button type="danger" @click="delSelecteds()" v-if="editable">删除</el-button>

      <div class="layout-bar-split" v-if="editable"></div>
      <div style="width:220px;" class="layout-bar-margin-right" v-if="editable">
        <el-select v-model="category" placeholder="选择分类重新生成" filterable>
          <el-option
            v-for="(category, inx) in categories" :key="inx"
            :label="category.title"
            :value="category.title"
            :disabled="category.disabled">
          </el-option>
        </el-select>
      </div>
      <el-button v-if="editable" type="primary" :loading="categoryRegenIng" @click="regenByCategory">重新生成</el-button>
    </div>

    <div class="layout-content" ref="container">
      <el-table
        :data="list" :height="height"
        key="table" border style="width: 100%"
        row-key="id"
        ref="table"
        v-loading="loading"
        @selection-change="whenSelectChanged"
      >
        <el-table-column type="index" width="50" />
        <el-table-column
          type="selection" width="40" align="center" :reserve-selection="true" fixed="left"
        />

        <el-table-column prop="id" label="id" width="60" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>
        <el-table-column prop="question_category" label="题目分类" min-width="120" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" inputType="options" :options="categoryOptions" :column="scope.column" @filter="filter" />
          </template>
        </el-table-column>
        <el-table-column prop="auto" label="手动创建" min-width="100" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" inputType="options" :options="autoOptions" :column="scope.column" @filter="filter" />
          </template>
        </el-table-column>
        <el-table-column prop="question_code" label="code" min-width="100" align="center"></el-table-column>
        <el-table-column prop="question_type" label="类型" min-width="160" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" inputType="options" :options="questionTypeOptions" :column="scope.column" @filter="filter" />
          </template>
        </el-table-column>
        <el-table-column prop="question_level" label="难度" min-width="100" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :filterable="true" inputType="options" :options="levelsOptions" :column="scope.column" :order="order" @order="onorder" @filter="filter" />
          </template>
          <template v-slot:default="scope">
            <el-select v-model="scope.row.question_level" @change="update(scope.row, 'question_level')">
              <el-option v-for="(level, inx) in levels" :key="inx" :label="level" :value="level"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="question_title" label="标题" min-width="160" align="center">
          <template v-slot:default="scope">
            <div class="flex-column">
              <el-input v-model="scope.row.question_title" class="flex-1"></el-input>
              <el-button link type="primary" class="row-col-btn" :loading="scope.row.ings.question_title" @click="update(scope.row, 'question_title')" v-if="editable">保存</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="question_text" label="文本" min-width="200" align="center">
          <template v-slot:default="scope">
            <div class="flex-column">
              <el-input v-model="scope.row.question_text" type="textarea" :rows="6" class="flex-1"></el-input>
              <el-button link type="primary" class="row-col-btn" :loading="scope.row.ings.question_text" @click="update(scope.row, 'question_text')" v-if="editable">保存</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="question_text_audio" label="语音" min-width="60" align="center">
          <template v-slot:default="scope">
            <div class="flex-column" v-if="scope.row.question_text_audio">
              <el-button link class="row-col-btn" type="primary" @click="play(scope.row.question_text_audio)">
                <i class="el-icon-video-play" />
              </el-button>
              <el-popover width="500" trigger="click" class="item" :content="scope.row.question_text_audio">
                <template #reference>
                  <el-button class="row-col-btn" link type="primary">URL</el-button>
                </template>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="question_stem_type" label="题干类型" min-width="100" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" inputType="options" :options="questionStemTypeOptions" :column="scope.column" @filter="filter" />
          </template>
        </el-table-column>
        <el-table-column prop="question_stem_picture" label="题干图片" min-width="80" align="center">
          <template v-slot:default="scope">
            <div class="flex-column">
              <img v-viewer class="headimg" :src="scope.row.question_stem_picture" v-if="scope.row.question_stem_picture" />
              <el-button link type="primary" style="margin-top:6px;" v-if="scope.row.imgRegenable" :loading="scope.row.stmImgRegening" @click="questionStmImgRegen(scope.row, 'question_stem_picture', 'question_stem_picture_task')">从新生成</el-button>
              <el-button link type="primary" style="margin-left:0" v-if="scope.row.imgRegenable" :loading="scope.row.stmImgRegening" @click="showQuestionImgUpload(scope.row, 'question_stem_picture', 'question_stem_picture_task')">上传</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="question_stem_text" label="题干文本" min-width="160" align="center">
          <template v-slot:default="scope">
            <div class="flex-column">
              <el-input v-model="scope.row.question_stem_text" type="textarea" :rows="6" class="flex-1"></el-input>
              <el-button link type="primary" class="row-col-btn" :loading="scope.row.ings.question_stem_text" @click="update(scope.row, 'question_stem_text')" v-if="editable">保存</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="question_stem_audio" label="题干音频" min-width="80" align="center">
          <template v-slot:default="scope">
            <div class="flex-column" v-if="scope.row.question_stem_audio">
              <el-button link class="row-col-btn" type="primary" @click="play(scope.row.question_stem_audio)">
                <i class="el-icon-video-play" />
              </el-button>
              <el-popover width="500" trigger="click" class="item" :content="scope.row.question_stem_audio">
                <template #reference>
                  <el-button class="row-col-btn" link type="primary">URL</el-button>
                </template>
              </el-popover>
              <el-popover width="500" trigger="click" class="item" >
                <template #reference>
                  <el-button class="row-col-btn" link type="primary">修改</el-button>
                </template>

                <div class="flex-column-center">
                  <el-input v-model="scope.row.questionStemAudioText" style="margin:12px;" />
                  <el-button type="primary" :loading="scope.row.questionStemAudioRegening" @click="regenStmAudio(scope.row)">修改</el-button>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="option_type" label="选项类型" min-width="100" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" inputType="options" :options="optionTypeOptions" :column="scope.column" @filter="filter" />
          </template>
        </el-table-column>

        <el-table-column prop="write_background" label="选项" min-width="660" align="center">
          <template v-slot:default="scope">
            <el-table :data="scope.row.options" border style="width:100%;">
              <el-table-column prop="id" label="id" width="80" align="center"></el-table-column>
              <el-table-column prop="option_code" label="code" width="120" align="center"></el-table-column>
              <el-table-column prop="option_type" label="类型" min-width="100" align="center"></el-table-column>
              <el-table-column prop="option_picture" label="图片" min-width="80" align="center">
                <template v-slot:default="optScope">
                  <div class="flex-column">
                    <img v-viewer class="headimg" :src="optScope.row.option_picture" v-if="optScope.row.option_picture" />
                    <el-button link type="primary" class="row-col-btn" v-if="optScope.row.imgRegenable" :loading="optScope.row.imgRegening" @click="optionImgRegen(scope.row, optScope.row)">从新生成</el-button>
                    <el-button link type="primary" class="row-col-btn" v-if="optScope.row.imgRegenable" :loading="optScope.row.imgRegening" style="margin-left:0" @click="showOptionImgUpload(scope.row, optScope.row)">上传</el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="option_audio" label="语音" width="100" align="center">
                <template v-slot:default="scope">
                  <div class="flex-column" v-if="scope.row.option_audio">
                    <el-button link class="row-col-btn" type="primary" @click="play(scope.row.option_audio)">
                      <i class="el-icon-video-play" />
                    </el-button>
                    <el-popover width="500" trigger="click" class="item" :content="scope.row.option_audio">
                      <template #reference>
                        <el-button class="row-col-btn" link type="primary">URL</el-button>
                      </template>
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="option_text" label="文本" width="150" align="center">
                <template v-slot:default="scope">
                  <div class="flex-column">
                    <el-input v-model="scope.row.option_text" class="flex-1"></el-input>
                    <el-button link type="primary" class="row-col-btn" @click="updateOption(scope.row, 'option_text')" v-if="editable">保存</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>

        <el-table-column prop="answer_codes" label="答案" min-width="120" align="center"></el-table-column>
        <el-table-column prop="answer_audio" label="答案语音" min-width="100" align="center">
          <template v-slot:default="scope">
            <el-popover width="500" trigger="click" v-if="scope.row.answer_audio" class="item" :content="scope.row.answer_audio">
              <template #reference>
                <el-button link type="primary">内容</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="answer_intro" label="答案解析" min-width="100" align="center">
          <template v-slot:default="scope">
            <el-popover width="500" trigger="click" v-if="scope.row.answer_intro" class="item" :content="scope.row.answer_intro">
              <template #reference>
                <el-button link type="primary">内容</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column prop="answer_intro" label="拾音时长(毫秒)" min-width="120" align="center">
          <template v-slot:default="scope">
            <div class="flex-column">
              <el-input v-model="scope.row.gap_duration" class="flex-1"></el-input>
              <el-button link type="primary" class="row-col-btn" :loading="scope.row.ings.gap_duration" @click="update(scope.row, 'gap_duration')" v-if="editable">保存</el-button>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="created_at" label="创建时间" width="150" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>
        <el-table-column prop="updated_at" label="更新时间" width="150" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>

        <el-table-column label="操作" width="150" align="center" fixed="right" v-if="editable">
          <template v-slot:default="scope">
            <div class="flex-center">
              <el-button link type="danger" @click="del([scope.row])" v-if="editable">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="layout-bbar">
      <page ref="page" @change="reload" />
    </div>

    <create v-if="create.visible" v-model:value="create.visible" :trackId="trackId" @done="reload()" />

    <input type="file" accept=".jpg,.jpeg,.png" style="display:none" ref="input" @change="fileSelected" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import page from '@/components/page/index'
import filterable from '@/components/table/header.filterable.vue'
import create from './create'
import options from './options'

export default {
  components: { page, filterable, create },
  props: {
    track: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      trackId: this.$route.params.trackId,
      regening: false,
      height: '200px',
      loading: false,
      list: [],
      order: {
        orderBy: 'id',
        orderType: 'asc'
      },
      params: {
        name: ''
      },
      selecteds: [],

      create: {
        visible: false,
      },

      ...options,
      currQuestion: null,
      currOption: null,

      regenStatus: null,

      categoryOptions: [],
      categories: [],
      category: null,
      categoryRegenIng: false
    }
  },

  computed: {
    ...mapState({
      resized: state => state.runtime.resized,
    }),

    editable() {
      return !this.track.audit_status
    }
  },

  async mounted() {
    this.resize()
    this.reload()
    this.reloadRegenStatus()
    this.reloadCategories()
  },

  beforeUnmount() {
    this.stopTimer()
  },

  methods: {
    async reload(noLoading) {
      try {
        if (noLoading !== true) this.loading = true
        const params = this.$refs.page.params({
          order_by: this.order.orderBy,
          order_type: this.order.orderType,
          track_id: this.trackId,
          ...this.params
        })
        const { data } = await this.request({
          method: 'GET',
          url: '/api/admin/resource/ai/question/closed/list',
          params: params
        })

        this.$refs.page.update(data)
        const selecteds = []
        let hasing = false
        for (const item of data.data) {
          item.auto = item.auto === 0 ? '是' : '否'
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
          item.updated_at = dayjs(item.updated_at).format('YYYY-MM-DD HH:mm')
          item.imgRegenable = !!item.question_stem_picture_task
          item.stmImgRegening = false
          item.questionStemAudioText = item.question_stem_audio_text || ''
          item.questionStemAudioRegening = false
          for (const opt of item.options) {
            opt.imgRegening = false
            opt.imgRegenable = opt.option_type.indexOf('picture') !== -1
          }
          item.ings = {
            question_title: false,
            question_text: false,
            question_stem_text: false
          }
        }
        this.list = data.data
        this.selecteds = selecteds.map(p => p.id)
        this.$nextTick(() => {
          if (selecteds.length > 0) this.$refs.table.toggleRowSelection(selecteds)
        })
        // if (hasing) this.reloadLater()
      } finally {
        if (noLoading !== true) this.loading = false
      }
    },

    async reloadCategories() {
      const { data } = await this.request({
        method: 'GET',
        url: '/api/admin/resource/ai/question/closed/categories'
      })

      this.categories = [
        { id: -1, title: '古诗', disabled: true,  },
        ...data.cn.map(p => {
          return {
            language: 'cn',
            ...p
          }
        }),
        { id: -2, title: '英语', disabled: true },
        ...data.en.map(p => {
          return {
            language: 'en',
            ...p
          }
        }),
      ].filter(p => p.language === this.track.language && p.category === this.track.ai_category)
      this.categoryOptions = this.categories.map(p => {
        return {
          id: p.title,
          title: p.title,
          disabled: p.disabled
        }
      })
    },

    showCreate() {
      this.create.visible = true
    },

    async regenStmAudio(row) {
      if (row.questionStemAudioRegening) return
      if (!row.questionStemAudioText) this.$message.error('请输入内容')
      try {
        row.questionStemAudioRegening = true

        const { data } = await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/question/closed/stm/audio/update',
          data: {
            track_id: this.trackId,
            question_id: row.id,
            question_stem_audio: row.questionStemAudioText
          }
        })

        row.question_stem_audio = data.url
        this.$message.success('修改成功!')
        this.play(data.url)
      } finally {
        row.questionStemAudioRegening = false
      }
    },

    async regen() {
      const yes = await this.confirm('确定要重新生成吗?')
      if (!yes) return
      if (this.regening) return
      try {
        this.$message.success('大概需要3~10分钟，请耐心等候')
        this.regening = true
        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/question/closed/regen',
          data: {
            id: this.trackId
          }
        })
        this.regenStatus = 'ing'
        this.nextRegenStatus()
      } catch(e) {
        console.log(e)
        this.regening = false
      }
    },

    async regenByCategory() {
      if (this.categoryRegenIng) return
      if (!this.category) return this.$message.error('请选择需要重新生成的分类')
      const yes = await this.confirm('确定要重新生成吗?')
      if (!yes) return

      try {
        this.categoryRegenIng = true
        this.$message.success('大概需要1~10分钟，请耐心等候')
        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/question/closed/regen/categories',
          data: {
            id: this.trackId,
            categories: [this.category]
          }
        })
        this.reload()
      } finally {
        this.categoryRegenIng = false
      }
    },

    async reloadRegenStatus() {
      const { data } = await this.request({
        method: 'POST',
        url: '/api/admin/resource/ai/track/question/closed/status',
        data: {
          id: this.trackId
        }
      })
      this.regenStatus = data || null
      this.regening = data === 'ing'
      if (data === 'ing') {
        this.nextRegenStatus()
        return
      } else if (data === 'error') {
        this.$message.success('重新生成失败')
      }
      this.reload()
    },

    stopTimer() {
      if (this.timer) clearTimeout(this.timer)
      this.timer = null
    },

    nextRegenStatus() {
      this.stopTimer()
      this.timer = setTimeout(() => {
        this.reloadRegenStatus()
      }, 3000)
    },

    async questionStmImgRegen(question) {
      try {
        question.stmImgRegening = true
        const form = new FormData()
        this.$message.success('大约需要1分钟，请稍等')
        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/question/closed/stm/image/regen',
          data: {
            question_id: question.id,
            track_id: this.trackId
          }
        })

        this.$message.success('生成成功')
        await this.reload()
      } finally {
        question.stmImgRegening = false
      }
    },

    async optionImgRegen(question, option) {
      try {
        option.imgRegening = true
        const form = new FormData()
        this.$message.success('大约需要1分钟，请稍等')
        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/question/option/image/regen',
          data: {
            question_id: question.id,
            option_id: option.id,
            track_id: this.trackId
          }
        })

        this.$message.success('生成成功')
        await this.reload()
      } finally {
        option.imgRegening = false
      }
    },

    showQuestionImgUpload(row) {
      this.$refs.input.click()
      this.currQuestion = row
      this.currOption = null
    },

    showOptionImgUpload(question, row) {
      this.$refs.input.click()
      this.currQuestion = question
      this.currOption = row
    },

    async fileSelected() {
      const file = this.$refs.input.files[0]
      this.$refs.input.value = ''
      if (this.currOption) {
        this.uploadOptionPic(file)
      } else {
        this.uploadQuestionStmPic(file)
      }
    },

    async uploadQuestionStmPic(file) {
      const row = this.currQuestion
      try {
        row.stmImgRegening = true
        const form = new FormData()
        form.append('file', file)
        form.append('id', row.id)
        form.append('track_id', this.trackId)
        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/question/closed/stm/image/upload',
          data: form
        })

        this.$message.success('上传成功')
        await this.reload()
      } finally {
        row.stmImgRegening = false
      }
    },

    async uploadOptionPic(file) {
      const row = this.currOption
      try {
        row.imgRegening = true
        const form = new FormData()
        form.append('file', file)
        form.append('id', row.id)
        form.append('track_id', this.trackId)
        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/question/option/image/upload',
          data: form
        })

        this.$message.success('上传成功')
        await this.reload()
      } finally {
        row.imgRegening = false
      }
    },

    async update(row, feild) {
      try {
        row.ings[feild] = true
        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/question/closed/update',
          data: {
            track_id: this.trackId,
            id: row.id,
            data: {
              [feild]: row[feild] || null
            }
          }
        })
        this.$message.success('修改成功')
        if (feild === 'question_text') this.reload()
      } finally {
        row.ings[feild] = false
      }
    },

    async updateOption(row, feild) {
      await this.request({
        method: 'POST',
        url: '/api/admin/resource/ai/track/question/option/update',
        data: {
          track_id: this.trackId,
          id: row.id,
          data: {
            [feild]: row[feild]
          }
        }
      })
      this.$message.success('修改成功')
    },

    play(url) {
      const audio = new Audio(url)
      audio.play()
    },

    onorder({ orderBy, orderType }) {
      this.order.orderBy = orderBy
      this.order.orderType = orderType
      this.reload()
    },

    filter({ id, value }) {
      this.params[id] = value
      this.reload()
    },

    resize() {
      this.height = this.$refs.container.clientHeight
    },

    whenSelectChanged(selection) {
      this.selecteds = selection.map(p => p.id)
    },

    async delSelecteds() {
      this.del(this.list.filter(p => this.selecteds.indexOf(p.id) !== -1))
    },

    async del(rows) {
      if (rows.length === 0) return this.$message.error('请选择数据')
      const yes = await this.confirm('确定要删除吗？')
      if (!yes) return
      const ids = rows.map(p => p.id)

      await this.request({
        method: 'POST',
        url: '/api/admin/resource/ai/track/question/closed/delete',
        data: {
          ids
        }
      })

      this.$message.success('删除成功')
      this.reload()
    }
  }
}
</script>

<style>
.headimg{
  max-width:60px;
  max-height:60px;
}
</style>
