<template>
  <div class="container layout-container">
    <div class="layout-bar layout-bar-tabs">
      <div class="el-tabs el-tabs-tab-only el-tabs--card el-tabs--top">
        <div class="el-tabs__header is-top">
          <div class="el-tabs__nav-wrap is-top">
            <div class="el-tabs__nav-scroll flex">
              <div class="el-tabs-bar">
                <el-button link @click="back">
                  <i class="el-icon-arrow-left"></i>返回
                </el-button>

                <el-button type="primary" :loading="publishing" @click="publish" v-if="editable">同步入库</el-button>
              </div>

              <div role="tablist" class="el-tabs__nav is-top" style="transform: translateX(0px);">
                <div
                  class="el-tabs__item is-top" v-for="(tab, inx) in tabs" :key="inx"
                  :class="{'is-active': tab.id === $route.meta.tab}"
                  @click="toTab(tab)"
                >{{tab.title}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layout-content" ref="container">
      <router-view @track="updateTrackInfo" :track="track" v-if="inited" />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import page from '@/components/page/index'
import filterable from '@/components/table/header.filterable.vue'

export default {
  components: { page, filterable },

  data() {
    return {
      trackId: this.$route.params.trackId,
      tabs: [
        { id: 'info', title: '信息' },
        { id: 'closed', title: '固定题' },
        { id: 'open', title: '开放题' },
      ],
      track: null,
      publishing: false,
      inited: false
    }
  },

  async mounted() {
    console.log(this.trackId)
    this.reload()
  },

  beforeUnmount() {
    this.stopTimer()
  },

  methods: {
    stopTimer() {
      if (this.timer) clearTimeout(this.timer)
      this.timer = null
    },

    back() {
      this.$router.back()
    },

    toTab(tab) {
      if (tab.id === this.$route.meta.tab) return
      this.$router.replace(`/p/resource/track/ai/index/${this.trackId}/${tab.id}`)
    },

    async reload() {
      const { data } = await this.request({
        method: 'GET',
        url: '/api/admin/resource/track/list',
        params: {
          start: 0,
          size: 1,
          id: this.trackId
        }
      })
      this.track = data.data[0]
      this.inited = true
    },

    updateTrackInfo(track) {
      this.track = track
    },

    async publish() {
      if (this.publishing) return
      try {
        this.publishing = true
        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/publish',
          data: {
            ids: [this.trackId]
          }
        })
        this.$message.success('同步入库成功')
      } finally {
        this.publishing = false
      }
    }
  }
}
</script>

<style>

</style>
