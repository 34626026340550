<template>
  <div class="container layout-container qclosed">
    <div class="layout-bar">
      <el-button type="primary" @click="reload()">刷新</el-button>
    </div>

    <div class="layout-content" ref="container" v-loading="loading">
      <div class="qclosed-form">
        <el-form ref="form" class="qclosed-form-wrapper" :model="form" label-width="200px" v-if="form">
          <el-form-item label="背景图片：" v-if="isCn">
            <div style="width:100%;" class="flex">
              <img v-viewer v-if="form.background_picture" :src="form.background_picture" class="qclosed-img" />
              <div class="flex-center import-form-upload" style="margin-left:12px;" v-if="editable">
                <el-upload
                  accept=".jpg,.jpeg,.png"
                  drag
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                >
                  <div class="import-form-upload-box flex-column-center">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__text">{{fileName}}</div>
                  </div>
                </el-upload>
              </div>
            </div>
            <el-button :loading="ings.background_picture" type="primary" style="margin-top:6px;" @click="regen()" v-if="editable">重新生成</el-button>
          </el-form-item>

          <el-form-item label="JSON：">
            <json-editor-vue class="qclosed-editor" v-model="form.summary_json" />
            <el-button :loading="ings.summary_json" type="primary" style="margin-top:6px;" @click="update('summary_json')" v-if="editable">修改保存</el-button>
            <el-button :loading="ings.summary_json" type="primary" style="margin-top:6px;" @click="regenSummary()" v-if="editable && isCn">重新生成(释义也会一起生成)</el-button>
            <el-button :loading="ings.summary_json" type="primary" style="margin-top:6px;" @click="regenSummary()" v-else-if="editable">重新生成</el-button>
          </el-form-item>

          <el-form-item label="释义：" v-if="isCn">
            <el-input v-model.trim="form.intro" type="textarea" :rows="5"></el-input>
            <el-button v-if="editable" :loading="ings.intro" type="primary" style="margin-top:6px;" @click="update('intro')">修改保存</el-button>
          </el-form-item>

          <el-form-item label="内容演绎/背景介绍：" v-if="isCn">
            <el-input v-model.trim="form.write_background" type="textarea" :rows="10"></el-input>
            <el-button v-if="editable" :loading="ings.write_background" type="primary" style="margin-top:6px;" @click="update('write_background')">修改保存</el-button>
            <el-button v-if="editable"  :loading="ings.write_background" type="primary" style="margin-top:6px;" @click="regenWriteBackground()">重新生成</el-button>
          </el-form-item>

          <el-form-item label="内容演绎/背景介绍-音频：" v-if="isCn">
            <el-input v-model="form.write_background_audio" :rows="10" disabled></el-input>
          </el-form-item>

          <el-form-item label="内容演绎/背景介绍-歌词：" v-if="isCn">
            <el-input v-model="form.write_background_lrc" :rows="10" disabled></el-input>
          </el-form-item>

        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'

function reset() {
  return {
    intro: '',
    background_picture: '',
    write_background: '',
    write_background_audio: '',
    write_background_lrc: '',
    summary_json: null
  }
}

export default {
  props: {
    track: {
      type: Object,
      default() {
        return null
      }
    }
  },

  data() {
    return {
      loading: false,
      saving: false,
      trackId: this.$route.params.trackId,
      form: null,
      isCn: false,
      ings: {
        regen: false,
        intro: false,
        background_picture: false,
        summary_json: false,
        write_background: false,
      },
      fileList: [],
      fileName: '',
    }
  },

  computed: {
    editable() {
      return !this.track.audit_status
    }
  },

  watch: {
    track() {
      this.init()
    }
  },

  async mounted() {
    this.init()
  },

  beforeUnmount() {
  },

  methods: {
    async init() {
      this.isCn = this.track.language === 'cn'
      const form = {
        intro: this.track.intro,
        background_picture: this.track.background_picture,
        write_background: this.track.write_background,
        write_background_audio: this.track.write_background_audio,
        write_background_lrc: this.track.write_background_lrc,
        summary_json: this.track.summary_json
      }
      this.form = {
        ...reset(),
        ...form,
        summary_json: form.summary_json ? JSON.parse(form.summary_json) : null
      }
    },

    async reload() {
      try {
        this.loading = true

        const { data } = await this.request({
          method: 'GET',
          url: '/api/admin/resource/track/list',
          params: {
            start: 0,
            size: 1,
            id: this.trackId
          }
        })
        this.$emit('track', data.data[0])
      } finally {
        this.loading = false
      }
    },

    async update(feild) {
      if (this.ings[feild]) return
      let val = this.form[feild]
      if (!val) return this.$message.error('字段值不能为空')
      if (feild === 'summary_json') {
        val = JSON.stringify(val)
      }
      try {
        this.ings[feild] = true
        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/update',
          data: {
            data: {
              [feild]: val,
            },
            id: this.trackId
          }
        })

        this.$message.success('修改成功')
        await this.reload()
      } finally {
        this.ings[feild] = false
      }
    },

    async regenSummary() {
      const feild = 'summary_json'
      try {
        this.ings[feild] = true
        this.$message.success('准备重新生成，预计需要3分钟左右')
        await this.request({
          timeout: 300000,
          method: 'POST',
          url: '/api/admin/resource/ai/track/summary/regen',
          data: {
            data: {
              [feild]: this.form[feild],
            },
            id: this.trackId
          }
        })

        this.$message.success('生成成功')
        await this.reload()
      } finally {
        this.ings[feild] = false
      }
    },

    async regenWriteBackground() {
      const feild = 'write_background'
      try {
        this.ings[feild] = true
        this.$message.success('准备重新生成，预计需要3分钟左右')
        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/write/background/regen',
          timeout: 300000,
          data: {
            data: {
              [feild]: this.form[feild],
            },
            id: this.trackId
          }
        })

        this.$message.success('生成成功')
        await this.reload()
      } finally {
        this.ings[feild] = false
      }
    },

    async regen() {
      const feild = 'background_picture'
      try {
        this.ings[feild] = true
        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/background/regen',
          data: {
            data: {
              [feild]: this.form[feild],
            },
            id: this.trackId
          }
        })

        this.$message.success('生成成功')
        await this.reload()
      } finally {
        this.ings[feild] = false
      }
    },

    beforeUpload(file) {
      this.form.file = file
      this.fileName = file.name
      this.upload(file)
      return false
    },

    async upload(file) {
      const feild = 'background_picture'
      try {
        this.ings[feild] = true
        const form = new FormData()
        form.append('file', file)
        form.append('id', this.trackId)
        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/background/upload',
          data: form
        })

        this.$message.success('上传成功')
        await this.reload()
      } finally {
        this.ings[feild] = false
      }
    }
  }
}
</script>

<style>
.qclosed-form{
  padding:12px;
}

.qclosed-form-wrapper{
  max-width:800px;
}

.qclosed-img{
  max-width:400px;
  max-height:200px;
}

.qclosed-editor{
  width:100%;
  height:360px;
}
</style>
