const options = {
  levels: [1, 2, 3],
  levelsOptions: [
    { id: 1, title: 1 },
    { id: 2, title: 2 },
    { id: 3, title: 3 },
  ],

  autoOptions: [
    { id: '0', title: '是' },
    { id: '1', title: '否' },
  ],

  questionTypeOptions: [
    { id: 'single_choice', title: 'single_choice' },
    { id: 'multiple_choice', title: 'multiple_choice' },
    { id: 'multiple_choice_sort', title: 'multiple_choice_sort' },
    { id: 'multiple_choice_match', title: 'multiple_choice_match' },
    { id: 'speech_evaluation', title: 'speech_evaluation' }
  ],
  questionStemTypeOptions: [
    { id: 'picture', title: 'picture' },
    { id: 'text', title: 'text' },
    { id: 'audio', title: 'audio' },
    { id: 'picture_text_audio', title: 'picture_text_audio' },
    { id: 'text_audio_picture', title: 'text_audio_picture' },
    { id: 'picture_text', title: 'picture_text' },
    { id: 'text_picture', title: 'text_picture' },
    { id: 'picture_audo', title: 'picture_audo' },
    { id: 'text_audio', title: 'text_audio' }
  ],
  optionTypeOptions: [
    { id: 'picture', title: 'picture' },
    { id: 'text', title: 'text' },
    { id: 'audio', title: 'audio' },
    { id: 'picture_text', title: 'picture_text' }
  ]
}

export default options
