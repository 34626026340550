<template>
  <div>
    <el-dialog title="手工创建" v-model="val" width="860px" :destroy-on-close="true" @closed="closed">
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="100px">
          <div class="auf-row flex">
            <el-form-item label="类型" prop="questionType" class="auf-item">
              <el-select v-model="form.questionType">
                <el-option v-for="(opt, inx) in questionTypeOptions" :key="inx" :label="opt.title" :value="opt.id"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="难度" prop="questionLevel" class="auf-item">
              <el-select v-model="form.questionLevel">
                <el-option v-for="(level, inx) in levels" :key="inx" :label="level" :value="level"></el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="auf-row flex">
            <el-form-item label="标题" prop="questionTitle" class="auf-item">
              <el-input v-model.trim="form.questionTitle"></el-input>
            </el-form-item>

            <el-form-item label="文本" prop="questionText" class="auf-item">
              <el-input v-model.trim="form.questionText"></el-input>
            </el-form-item>
          </div>

          <div class="auf-row flex">
            <el-form-item label="语音" prop="questionTextAudio" class="auf-item">
              <el-input v-model.trim="form.questionTextAudio"></el-input>
            </el-form-item>

            <el-form-item label="题干类型" prop="questionStemType" class="auf-item">
              <el-select v-model="form.questionStemType">
                <el-option v-for="(opt, inx) in questionStemTypeOptions" :key="inx" :label="opt.title" :value="opt.id"></el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="auf-row flex">
            <el-form-item label="题干文本" prop="questionStemText" class="auf-item">
              <el-input v-model.trim="form.questionStemText"></el-input>
            </el-form-item>
            <el-form-item label="题干图片" prop="questionStemPicture" class="auf-item">
              <div class="flex">
                <img class="auf-img" :src="form.questionStemPicture" v-if="form.questionStemPicture" />
                <el-button link type="primary" :loading="form.imgUploading" @click="showQuestionImgUpload(form, 'questionStemPicture')">上传</el-button>
              </div>
            </el-form-item>
          </div>

          <div class="auf-row flex">
            <el-form-item label="题干语音" prop="questionStemAudio" class="auf-item">
              <el-input v-model.trim="form.questionStemAudio"></el-input>
            </el-form-item>
            <el-form-item label="选项类型" prop="optionType" class="auf-item">
              <el-select v-model.trim="form.optionType">
                <el-option v-for="(opt, inx) in optionTypeOptions" :key="inx" :label="opt.title" :value="opt.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="auf-row flex">
            <el-form-item label="答案解析" prop="answerIntro" class="auf-item">
              <el-input v-model.trim="form.answerIntro"></el-input>
            </el-form-item>
            <el-form-item label="答案语音" prop="answerAudio" class="auf-item">
              <el-input v-model.trim="form.answerAudio"></el-input>
            </el-form-item>
          </div>
          <div class="auf-row flex">
            <el-form-item label="答案" prop="answerCodes" class="auf-item-full">
              <el-input v-model.trim="form.answerCodes"></el-input>
            </el-form-item>
          </div>

          <div>
            <el-table :data="form.options" border style="width:100%;">
              <el-table-column prop="optionCode" label="code" width="160" align="center">
                <template v-slot:default="scope">
                  <div>{{code(scope.$index)}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="optionType" label="类型" width="120" align="center">
                <template v-slot:default="scope">
                  <el-form-item prop="optionType" label-width="0">
                    <el-select v-model="scope.row.optionType">
                      <el-option v-for="(opt, inx) in optionTypeOptions" :key="inx" :label="opt.title" :value="opt.id"></el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="optionPicture" label="图片" width="120" align="center">
                <template v-slot:default="scope">
                  <el-form-item prop="optionAudio" label-width="0">
                    <div class="flex">
                      <img class="auf-img" :src="scope.row.optionPicture" v-if="scope.row.optionPicture" />
                      <el-button link type="primary" :loading="scope.row.imgUploading" @click="showQuestionImgUpload(scope.row, 'optionPicture')">上传</el-button>
                    </div>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="optionAudio" label="语音" min-width="120" align="center">
                <template v-slot:default="scope">
                  <el-form-item prop="optionAudio" label-width="0">
                    <el-input v-model.trim="scope.row.optionAudio"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="optionText" label="文本" min-width="120" align="center">
                <template v-slot:default="scope">
                  <el-form-item prop="optionText" label-width="0">
                    <el-input v-model.trim="scope.row.optionText"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="120" align="center">
                <template v-slot:default="scope">
                  <div class="flex-center">
                    <el-button link type="danger" v-if="form.options.length > 1" @click="delOption(scope.$index)">删除</el-button>
                    <el-button link type="primary" @click="addOption(scope.$index)">添加</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form>
      </div>
      <template v-slot:footer>
        <div class="flex-center">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" :loading="saving" @click="save">确定</el-button>
        </div>
      </template>
    </el-dialog>

    <input type="file" accept=".jpg,.jpeg,.png" style="display:none" ref="input" @change="fileSelected" />
  </div>
</template>

<script>
import mixin from '@/commons/popup.mixin.js'
import options from './options'

export default {
  props: {
    trackId: {
      type: [Number, String],
      default: null
    }
  },
  mixins: [mixin],
  data() {
    return {
      form: {
        questionType: 'single_choice',
        questionLevel: 1,
        questionTitle: '',
        questionText: '',
        questionTextAudio: '',
        questionStemType: 'text',
        questionStemPicture: null,
        questionStemText: '',
        questionStemAudio: '',
        optionType: 'text',
        answerCodes: '',
        answerIntro: '',
        answerAudio: '',
        options: [
          {
            optionType: 'text',
            optionCode: '',
            optionText: '',
            optionAudio: '',
            optionPicture: null,

            imgUploading: false
          }
        ],

        imgUploading: false,
      },

      rules: {
        answerCodes: [
          { required: true, message: '答案不能为空' },
          {
            asyncValidator: (rule, value) => {
              return new Promise((r, j) => {
                let ok = false
                if (this.form.questionType === 'single_choice' || this.form.questionType === 'speech_evaluation') {
                  for (let inx=0; inx<this.form.options.length; inx++) {
                    const opt = this.form.options[inx]
                    opt.optionCode = this.code(inx)
                    if (opt.optionCode === this.form.answerCodes) ok = true
                  }
                } else {
                  try {
                    JSON.parse(this.form.answerCodes)
                    ok = true
                  } catch(e) {
                    console.log('答案解析失败', e)
                  }
                }

                if (ok) r()
                else j(new Error('答案格式不正确'))
              })
            }
          }
        ]
      },
      ...options,

      uploadPojo: null,
      uploadFeild: null,
      saving: false
    }
  },

  created() {
  },

  methods: {

    addOption(inx) {
      this.form.options.splice(inx+1, 0, {
        optionType: 'text',
        optionCode: '',
        optionText: '',
        optionAudio: '',
        optionPicture: null
      })
    },

    delOption(inx) {
      this.form.options.splice(inx, 1)
    },

    code(inx) {
      return `c_${this.trackId}_{{questionId}}_${inx+1}`
    },

    showQuestionImgUpload(pojo, feild) {
      this.$refs.input.click()
      this.uploadPojo = pojo
      this.uploadFeild = feild
    },

    async fileSelected() {
      const file = this.$refs.input.files[0]
      this.$refs.input.value = ''
      if (this.uploadFeild === 'questionStemPicture') {
        this.uploadQuestionStmPic(file)
      } else {
        this.uploadOptionPic(file)
      }
    },

    async uploadQuestionStmPic(file) {
      const row = this.uploadPojo
      try {
        row.imgUploading = true
        const form = new FormData()
        form.append('file', file)
        form.append('track_id', this.trackId)
        const { data } = await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/question/closed/stm/image/upload',
          data: form
        })

        this.$message.success('上传成功')
        this.uploadPojo[this.uploadFeild] = data
      } finally {
        row.imgUploading = false
      }
    },

    async uploadOptionPic(file) {
      const row = this.uploadPojo
      try {
        row.imgUploading = true
        const form = new FormData()
        form.append('file', file)
        form.append('track_id', this.trackId)
        const { data } = await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/question/option/image/upload',
          data: form
        })

        this.$message.success('上传成功')
        this.uploadPojo[this.uploadFeild] = data
      } finally {
        row.imgUploading = false
      }
    },

    async save() {
      this.$refs.form.validate((yes) => {
        if (yes) this.doSave()
      })
    },

    async doSave() {
      if (this.saving) return
      try {
        this.saving = true
        const form = this.form
        const question = {
          question_code: `c_${this.trackId}_{{questionId}}`,
          question_type: form.questionType,
          question_level: form.questionLevel,
          question_title: form.questionTitle || null,
          question_text: form.questionText || null,
          question_text_audio: form.questionTextAudio || null,
          question_stem_type: form.questionStemType,
          question_stem_picture: form.questionStemPicture || null,
          question_stem_text: form.questionStemText || null,
          question_stem_audio: form.questionStemAudio || null,
          option_type: form.optionType,
          answer_codes: form.answerCodes,
          answer_intro: form.answerIntro || null,
          answer_audio: form.answerAudio || null,
          options: form.options.map((p, inx) => {
            return {
              option_type: p.optionType,
              option_code: this.code(inx),
              option_text: p.optionText || null,
              option_audio: p.optionAudio || null,
              option_picture: p.optionPicture || null
            }
          })
        }

        const params = {
          track_id: this.trackId,
          question
        }

        await this.request({
          method: 'post',
          url: '/api/admin/resource/ai/track/question/closed/create',
          data: params
        })

        this.$emit('done')
        this.close()
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style>
.auf-item{
  width:400px;
}

.auf-item-full{
  width:100%;
}

.auf-row{
}

.auf-img{
  max-width:200px;
  max-height:100px;
}
</style>
