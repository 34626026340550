export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      val: this.value
    }
  },
  methods: {
    closed() {
      this.$emit('update:value', false)
    },
    close() {
      this.val = false
    }
  }
}
