<template>
  <div class="container layout-container qclosed" v-loading="regening" element-loading-text="正在重新生成，大约需要3~5分钟">
    <div class="layout-bar">
      <el-button type="primary" @click="reload()">刷新</el-button>
      <el-button type="primary" @click="regen()" v-if="editable">重新生成</el-button>
      <el-button type="danger" @click="delSelecteds()" v-if="editable">删除</el-button>
    </div>

    <div class="layout-content" ref="container">
      <el-table
        :data="list" :height="height"
        key="table" border style="width: 100%"
        row-key="id"
        ref="table"
        v-loading="loading"
        @selection-change="whenSelectChanged"
      >
        <el-table-column
          type="selection" width="40" align="center" :reserve-selection="true" fixed="left"
        />

        <el-table-column prop="id" label="id" width="80" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>
        <el-table-column prop="question_category" label="题目分类" min-width="120" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :column="scope.column" @filter="filter" />
          </template>
        </el-table-column>

        <el-table-column prop="question_code" label="code" min-width="100" align="center"></el-table-column>
        <el-table-column prop="question_level" label="难度" min-width="100" align="center">
          <template v-slot:default="scope">
            <el-select v-model="scope.row.question_level" @change="update(scope.row, 'question_level')">
              <el-option v-for="(level, inx) in levels" :key="inx" :label="level" :value="level"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="question_title" label="标题" min-width="160" align="center">
          <template v-slot:default="scope">
            <div class="flex-column">
              <el-input v-model="scope.row.question_title" class="flex-1"></el-input>
              <el-button link type="primary" class="row-col-btn" :loading="scope.row.ings.question_title" @click="update(scope.row, 'question_title')" v-if="editable">保存</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="question_text" label="文本" min-width="200" align="center">
          <template v-slot:default="scope">
            <div class="flex-column">
              <el-input v-model="scope.row.question_text" type="textarea" :rows="6" class="flex-1"></el-input>
              <el-button link type="primary" class="row-col-btn"  :loading="scope.row.ings.question_text" @click="update(scope.row, 'question_text')" v-if="editable">保存</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="question_text_audio" label="语音" min-width="60" align="center">
          <template v-slot:default="scope">
            <div class="flex-column" v-if="scope.row.question_text_audio">
              <el-button link class="row-col-btn" type="primary" @click="play(scope.row.question_text_audio)">
                <i class="el-icon-video-play" />
              </el-button>
              <el-popover width="500" trigger="click" class="item" :content="scope.row.question_text_audio">
                <template #reference>
                  <el-button class="row-col-btn" link type="primary">URL</el-button>
                </template>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="question_stem_type" label="题干类型" min-width="100" align="center"></el-table-column>
        <el-table-column prop="question_stem_picture" label="题干图片" min-width="80" align="center">
          <template v-slot:default="scope">
            <img v-viewer class="headimg" :src="scope.row.question_stem_picture" v-if="scope.row.question_stem_picture" />
          </template>
        </el-table-column>
        <el-table-column prop="question_stem_text" label="题干文本" min-width="100" align="center">
          <template v-slot:default="scope">
            <div class="flex-column">
              <el-input v-model="scope.row.question_stem_text" class="flex-1"></el-input>
              <el-button link type="primary" class="row-col-btn" :loading="scope.row.ings.question_stem_text" @click="update(scope.row, 'question_stem_text')" v-if="editable">保存</el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="question_stem_audio" label="题干音频" min-width="100" align="center">
          <template v-slot:default="scope">
            <div class="flex-column" v-if="scope.row.question_stem_audio">
              <el-button link class="row-col-btn" type="primary" @click="play(scope.row.question_stem_audio)">
                <i class="el-icon-video-play" />
              </el-button>
              <el-popover width="500" trigger="click" class="item" :content="scope.row.question_stem_audio">
                <template #reference>
                  <el-button class="row-col-btn" link type="primary">URL</el-button>
                </template>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="answer_intro" label="答案解析" min-width="100" align="center">
          <template v-slot:default="scope">
            <el-popover width="500" trigger="click" v-if="scope.row.answer_intro" class="item" :content="scope.row.answer_intro">
              <template #reference>
                <el-button link type="primary">内容</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间" width="150" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>
        <el-table-column prop="updated_at" label="更新时间" width="150" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>

        <el-table-column label="操作" width="150" align="center" fixed="right" v-if="editable">
          <template v-slot:default="scope">
            <div class="flex-center">
              <el-button link type="danger" @click="del([scope.row])">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="layout-bbar">
      <page ref="page" @change="reload" />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import page from '@/components/page/index'
import filterable from '@/components/table/header.filterable.vue'

export default {
  components: { page, filterable },
  props: {
    track: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      trackId: this.$route.params.trackId,
      regening: false,

      height: '200px',
      loading: false,
      list: [],
      order: {
        orderBy: 'id',
        orderType: 'asc'
      },
      params: {
        name: ''
      },
      selecteds: [],
      levels: [1, 2, 3]
    }
  },

  computed: {
    ...mapState({
      resized: state => state.runtime.resized,
    }),

    editable() {
      return !this.track.audit_status
    }
  },

  async mounted() {
    this.resize()
    this.reload()
    this.reloadRegenStatus()
  },

  beforeUnmount() {
    this.stopTimer()
  },

  methods: {
    async reload(noLoading) {
      try {
        if (noLoading !== true) this.loading = true
        const params = this.$refs.page.params({
          order_by: this.order.orderBy,
          order_type: this.order.orderType,
          track_id: this.trackId,
          ...this.params
        })
        const { data } = await this.request({
          method: 'GET',
          url: '/api/admin/resource/ai/question/open/list',
          params: params
        })

        this.$refs.page.update(data)
        const selecteds = []
        let hasing = false
        for (const item of data.data) {
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
          item.updated_at = dayjs(item.updated_at).format('YYYY-MM-DD HH:mm')
          item.ings = {
            question_title: false,
            question_text: false,
            question_stem_text: false
          }
        }
        this.list = data.data
        this.selecteds = selecteds.map(p => p.id)
        this.$nextTick(() => {
          if (selecteds.length > 0) this.$refs.table.toggleRowSelection(selecteds)
        })
        // if (hasing) this.reloadLater()
      } finally {
        if (noLoading !== true) this.loading = false
      }
    },

    async regen() {
      const yes = await this.confirm('确定要重新生成吗?')
      if (!yes) return
      if (this.regening) return
      try {
        this.$message.success('大概需要1~3分钟，请耐心等候')
        this.regening = true
        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/question/open/regen',
          data: {
            id: this.trackId
          }
        })

        this.regenStatus = 'ing'
        this.nextRegenStatus()
      } catch(e) {
        console.log(e)
        this.regening = false
      }
    },

    async reloadRegenStatus() {
      const { data } = await this.request({
        method: 'POST',
        url: '/api/admin/resource/ai/track/question/open/status',
        data: {
          id: this.trackId
        }
      })
      this.regenStatus = data || null
      this.regening = data === 'ing'
      if (data === 'ing') {
        this.nextRegenStatus()
        return
      } else if (data === 'error') {
        this.$message.success('重新生成失败')
      }
      this.reload()
    },

    stopTimer() {
      if (this.timer) clearTimeout(this.timer)
      this.timer = null
    },

    nextRegenStatus() {
      this.stopTimer()
      this.timer = setTimeout(() => {
        this.reloadRegenStatus()
      }, 3000)
    },

    play(url) {
      const audio = new Audio(url)
      audio.play()
    },

    async update(row, feild) {
      try {
        row.ings[feild] = true
        await this.request({
          method: 'POST',
          url: '/api/admin/resource/ai/track/question/open/update',
          data: {
            track_id: this.trackId,
            id: row.id,
            data: {
              [feild]: row[feild] || null
            }
          }
        })
        this.$message.success('修改成功')
        if (feild === 'question_text') this.reload()
      } finally {
        row.ings[feild] = false
      }
    },

    onorder({ orderBy, orderType }) {
      this.order.orderBy = orderBy
      this.order.orderType = orderType
      this.reload()
    },

    filter({ id, value }) {
      this.params[id] = value
      this.reload()
    },

    resize() {
      this.height = this.$refs.container.clientHeight
    },

    whenSelectChanged(selection) {
      this.selecteds = selection.map(p => p.id)
    },

    async delSelecteds() {
      this.del(this.list.filter(p => this.selecteds.indexOf(p.id) !== -1))
    },

    async del(rows) {
      if (rows.length === 0) return this.$message.error('请选择数据')
      const yes = await this.confirm('确定要删除吗？')
      if (!yes) return
      const ids = rows.map(p => p.id)

      await this.request({
        method: 'POST',
        url: '/api/admin/resource/ai/track/question/open/delete',
        data: {
          ids
        }
      })

      this.$message.success('删除成功')
      this.reload()
    }
  }
}
</script>

<style>
.headimg{
  max-width:60px;
  max-height:60px;
}
</style>
