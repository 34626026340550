<template>
  <div>
    <el-pagination
      background layout="prev, pager, next"
      :current-page="currPage" :page-size="pageSize" :total="total"
      :page-sizes="sizes"
      @size-change="onPageSize"
      @current-change="onPage"
    />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 20
    },

    page: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      start: 0,
      total: 0,

      pageSize: this.size,
      currPage: this.page,

      sizes: [ 10, 20, 30, 40, 50, 100, 200 ]
    }
  },

  methods: {
    reset() {
      this.start = 0
      this.currPage = 1
    },

    params(params) {
      if (!params) params = {}
      return {
        ...params,
        start: this.start,
        size: this.pageSize
      }
    },

    update({ total }) {
      return this.total = total
    },

    onPageSize(size) {
      this.pageSize = size
      this.start = (this.currPage - 1) * this.pageSize
      this.$emit('change', { page: this.currPage, size: this.pageSize })
    },

    onPage(curr) {
      this.currPage = curr
      this.start = (this.currPage - 1) * this.pageSize
      this.$emit('change', { page: this.currPage, size: this.pageSize })
    }
  }
}
</script>
