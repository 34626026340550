<template>
  <div class="flex">
    <div class="flex-1">{{column.label}}</div>
    <el-popover
      v-if="filterable"
      v-model:visible="filterVisible"
      class="col-filter"
      trigger="click"
      width="300px"
    >
      <template #reference>
        <i class="col-header-icon iconfont icon-filter-filling" :class="{ actived: filtered }" />
      </template>
      <div>
        <div>
          <el-input v-if="inputType === 'text'" v-model.trim="value" placeholder="请输入内容"></el-input>
          <div v-else-if="inputType === 'boolean'" class="filter-radio">
            <el-radio
              v-model="value"
              label="1"
              v-for="(item, inx) in yesnoOptions"
              :key="inx"
              :label="item.title"
              :value="item.id"
            >{{item.title}}</el-radio>
          </div>

          <div v-else-if="inputType === 'options'" class="filter-radio">
            <div
              v-for="(item, inx) in options"
              :key="inx"
            >
              <el-radio
                v-if="!item.disabled"
                v-model="value"
                label="1"
                :label="item.title"
                :value="item.id"
              >{{item.title}}</el-radio>
              <div v-else style="margin-top:6px;">{{item.title}}</div>
            </div>
          </div>

        </div>
        <div class="flex-center icon-filter-bbar">
          <el-button link @click="reset">重置</el-button>
          <el-button link type="primary" @click="ok">确定</el-button>
        </div>
      </div>
    </el-popover>
    <div v-if="orderable" class="col-order flex-column-center">
      <i class="col-header-icon iconfont icon-up" @click="onorder('asc')" :class="{ actived: order.orderBy === column.property && order.orderType === 'asc' }" />
      <i class="col-header-icon iconfont icon-down" @click="onorder('desc')" :class="{ actived: order.orderBy === column.property && order.orderType === 'desc' }" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    column: {
      type: Object,
      default() {
        return null
      }
    },

    inputType: {
      type: String,
      default: 'text'
    },

    orderable: {
      type: Boolean,
      default: false
    },

    order: {
      type: Object,
      default() {
        return null
      }
    },

    filterable: {
      type: Boolean,
      default: false
    },

    options: {
      type: Array,
      default() {
        return []
      }
    },

    filterValue: {
      type: [String, Number],
      default: ''
    }
  },

  data() {
    const value = this.filterValue === null || this.filterValue === undefined ? '' : this.filterValue
    let filtered = value !== null && value !== undefined && value !== ''
    return {
      filtered,
      value,
      filterVisible: false,

      yesnoOptions: [
        { id: 0, title: '0(否)' },
        { id: 1, title: '1(是)' },
      ]
    }
  },

  methods: {
    nothing() {},

    onorder(type) {
      if (type === this.order.orderType && this.column.property === this.order.orderBy) {
        return
      }
      this.$emit('order', {
        orderType: type,
        orderBy: this.column.property
      })
    },

    reset() {
      this.value = ''
      this.ok()
    },

    ok() {
      this.filtered = this.value !== null && this.value !== undefined && this.value !== ''
      this.filterVisible = false
      this.$emit('filter', {
        id: this.column.property,
        value: this.value
      })
    }
  }
}
</script>

<style>
.col-header-icon {
  color:var(--el-table-header-text-color);
  cursor:pointer;
}

.col-header-icon.actived{
  color:var(--el-menu-active-color);
}

.col-order, .col-filter{
  margin-left:6px;
}

.col-filter i{
  font-size:16px;
}

.col-order i {
  font-size:8px;
  line-height:8px;
}

.icon-filter-bbar{
  margin-top:12px;
}

.filter-radio{
  max-height:300px;
  overflow-y:auto;
  display:flex;
  flex-flow:column;
  justify-content:flex-start;
  align-items:flex-start;
}
</style>
